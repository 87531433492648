import { useCallback, useEffect, useState } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha, useTheme } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import { useRouter } from 'src/routes/hook';
import isEqual from 'lodash/isEqual';
import { enqueueSnackbar } from 'notistack';
//
import { ClassTemplatesService, Direction } from '../../../api';
import { useLocales } from '../../../locales';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
import { useSettingsContext } from '../../../components/settings';
import { useBoolean } from '../../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../../redux/store';
import { IUserTableFilters, IUserTableFilterValue } from '../../../types/user';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { RouterLink } from '../../../routes/components';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import { getClassTemplates } from '../../../redux/slices/class-template';
import ClassTemplateTableRow from '../class-template-table-row';
import MemberTableToolbar from '../../member/member-table-toolbar';
import MemberTableFiltersResult from '../../member/member-table-filters-result';

// ----------------------------------------------------------------------

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  status: 'active',
};

// ----------------------------------------------------------------------

export default function ClassTemplateList() {
  useTheme();

  const { t } = useLocales();
  const TABLE_HEAD = [
    { id: 'title', label: t('Title'), align: 'left' },
    { id: 'gym', label: t('Gym'), align: 'left' },
    { id: 'daysOfWeek', label: t('Days'), align: 'left' },
    { id: 'begins', label: t('Begins'), align: 'left' },
    { id: 'rating', label: t('Rating'), align: 'left' },
    { id: 'fillRate', label: t('Fill rate'), align: 'left' },
    { id: 'validFrom', label: t('Valid from'), align: 'left' },
    { id: 'validTo', label: t('Valid to'), align: 'left' },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onResetPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[2].id,
    defaultOrder: 'desc',
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.classTemplate.templates);

  const totalNumberOfMemberships = useSelector(
    (state) => state.classTemplate.totalNumberOfTemplates
  );

  const tableStats = useSelector((state) => state.classTemplate.stats);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getClassTemplates({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        isActive: true,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableStats.active },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const request = async () => {
        await ClassTemplatesService.deleteTemplate({ id });

        enqueueSnackbar(t('Deleted!'));

        dispatch(
          getClassTemplates({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            isActive: true,
          })
        );
      };

      request();
    },
    [dispatch, order, orderBy, page, rowsPerPage, t]
  );
  useCallback(
    (id: number) => {
      router.push(paths.classTemplates.view(id));
    },
    [router]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.classTemplates.edit(id));
    },
    [router]
  );
  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Planning')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Class Templates')}`,
              href: paths.classTemplates.root,
            },
            { name: `${t('List')}` },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.classTemplates.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('Schedule')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <MemberTableToolbar filters={filters} onFilters={handleFilters} onExport={() => {}} />

          {canReset && (
            <MemberTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              results={totalNumberOfMemberships}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={() => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <ClassTemplateTableRow
                      key={row.id}
                      row={row}
                      onEditRow={() => handleEditRow(row.id!)}
                      onDeleteRow={() => handleDeleteRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMemberships)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfMemberships}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
